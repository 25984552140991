<template>
    <div>

        <!--Create Modal-->
        <b-modal no-close-on-backdrop scrollable @hidden="hideModal" :title="upper(objType.one) + ' Creator'"
                 class="modal-dark" v-model="createModal">
            <b-tabs v-if="tabs">
                <b-tab :title="tabs[0]" active>
                    <div v-for="field in formFields" :key="field.name">
                        <h3 v-if="field.heading">{{ field.label }}</h3>
                        <div v-else-if="field.name==='password'">
                            <label class="form-check-label">{{ field.label }}:</label>
                            <b-input-group>
                                <b-input-group-text v-if="field.type==='password'" slot="append">
                                    <fa-icon :icon="['fas', 'eye']" @click='field.type="text"'/>
                                </b-input-group-text>
                                <b-input-group-text v-else slot="append">
                                    <fa-icon :icon="['fas', 'eye-slash']" @click="field.type='password'"/>
                                </b-input-group-text>
                                <b-form-input @keyup.enter="createEnterButton"
                                              :class="{ 'border-danger': ! newObject[field.name]}"
                                              :placeholder="field.placeholder || field.label" :type="field.type"
                                              v-model="newObject[field.name]"/>
                            </b-input-group>
                        </div>
                        <div v-else>
                            <label class="form-check-label">{{ field.label }}:</label>
                            <input @keyup.enter="createEnterButton" class="form-control"
                                   v-bind:class="{ 'border-danger': ! newObject[field.name] && field.required}"
                                   :placeholder="field.placeholder || field.label" :type="field.type"
                                   v-model="newObject[field.name]">
                        </div>
                        <br>
                    </div>
                    <slot name="createModalInput"></slot>
                </b-tab>
                <b-tab v-for="(tab, index) in tabs.slice(1)" :key="tab" :title="tab">
                    <slot :name="'createModalTabInput' + (index+1)"></slot>
                </b-tab>
            </b-tabs>
            <div v-else>
                <div v-for="field in formFields" :key="field.name">
                    <h3 v-if="field.heading">{{ field.label }}</h3>
                    <div v-else-if="field.name==='password'">
                        <label class="form-check-label">{{ field.label }}:</label>
                        <b-input-group>
                            <b-input-group-text v-if="field.type==='password'" slot="append">
                                <fa-icon :icon="['fas', 'eye']" @click='field.type="text"'/>
                            </b-input-group-text>
                            <b-input-group-text v-else slot="append">
                                <fa-icon :icon="['fas', 'eye-slash']" @click="field.type='password'"/>
                            </b-input-group-text>
                            <b-form-input @keyup.enter="createEnterButton"
                                          v-bind:class="{ 'border-danger': ! newObject[field.name]}"
                                          :placeholder="field.placeholder || field.label" :type="field.type"
                                          v-model="newObject[field.name]"/>
                        </b-input-group>
                    </div>
                    <div v-else>
                        <label class="form-check-label">{{ field.label }}:</label>
                        <input @keyup.enter="createEnterButton" class="form-control"
                               v-bind:class="{ 'border-danger': ! newObject[field.name] && field.required}"
                               :placeholder="field.placeholder || field.label" :type="field.type"
                               v-model="newObject[field.name]">
                    </div>
                    <br>
                </div>
                <slot name="createModalInput"></slot>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createModal=false">Cancel</b-button>
                <b-button :disabled="createFormDisabledOk()" size="sm" variant="primary" @click="createSubmit">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Disable Modal-->
        <b-modal no-close-on-backdrop ok-title="Submit" :title="'Confirm Disable'" class="modal-dark"
                 v-model="disableModal">
            <p>If you would like to disable this {{ objType.one }} please type the name below.</p>
            <br>
            <p>Enter "{{ curObject[nameField] }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input @keyup.enter="disableEnterButton" v-model="nameVerification" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="disableModal=false">Cancel</b-button>
                <b-button :disabled="nameVerification !== curObject[nameField]" size="sm" variant="warning"
                          @click="disableEnable">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Edit Modal-->
        <b-modal no-close-on-backdrop :title=" 'Edit ' + upper(objType.one)" class="modal-dark" v-model="editModal"
                 @hidden="hideModal">
            <b-tabs v-if="tabs">
                <b-tab :title="tabs[0]" active>
                    <div v-for="field in formFields" :key="field.name">
                        <h3 v-if="field.heading" style="text-align: center; margin-bottom: 0">{{ field.label }}</h3>
                        <div v-else-if="field.name==='password'">
                            <label class="form-check-label">{{ field.label }}:</label>
                            <b-input-group v-if="field.type==='password'">
                                <b-input-group-text slot="append">
                                    <fa-icon :icon="['fas', 'eye']" @click='field.type="text"'/>
                                </b-input-group-text>
                                <b-form-input @keyup.enter="editEnterButton"
                                              v-bind:class="{ 'border-danger': curObject[field.name]==='' && field.required}"
                                              :placeholder="field.placeholder || field.label" :type="field.type"
                                              v-model="curObject[field.name]"/>
                            </b-input-group>
                            <b-input-group v-else>
                                <b-input-group-text slot="append">
                                    <fa-icon :icon="['fas', 'eye-slash']" @click="field.type='password'"/>
                                </b-input-group-text>
                                <b-form-input @keyup.enter="editEnterButton"
                                              v-bind:class="{ 'border-danger': curObject[field.name]==='' && field.required}"
                                              :placeholder="field.placeholder || field.label" :type="field.type"
                                              v-model="curObject[field.name]"></b-form-input>
                            </b-input-group>
                        </div>
                        <div v-else>
                            <label class="form-check-label">{{ field.label }}:</label>
                            <b-form-input @keyup.enter="editEnterButton"
                                          v-bind:class="{ 'border-danger': curObject[field.name]==='' && field.required}"
                                          :placeholder="field.placeholder || field.label" :type="field.type"
                                          v-model="curObject[field.name]"/>
                        </div>
                        <br>
                    </div>
                    <slot name="editModalInput"></slot>
                </b-tab>
                <b-tab v-for="(tab, index) in tabs.slice(1)" :key="tab" :title="tab">
                    <slot :name="'editModalTabInput' + (index+1)"></slot>
                </b-tab>
            </b-tabs>

            <div v-else>
                <div v-for="field in formFields" :key="field.name">
                    <h3 v-if="field.heading" style="text-align: center; margin-bottom: 0">{{ field.label }}</h3>
                    <div v-else-if="field.name==='password'">
                        <label class="form-check-label">{{ field.label }}:</label>
                        <b-input-group v-if="field.type==='password'">
                            <b-input-group-text slot="append">
                                <fa-icon :icon="['fas', 'eye']" @click='field.type="text"'/>
                            </b-input-group-text>
                            <b-form-input @keyup.enter="editEnterButton"
                                          v-bind:class="{ 'border-danger': curObject[field.name]==='' && field.required}"
                                          :placeholder="field.placeholder || field.label" :type="field.type"
                                          v-model="curObject[field.name]"/>
                        </b-input-group>
                        <b-input-group v-else>
                            <b-input-group-text slot="append">
                                <fa-icon :icon="['fas', 'eye-slash']" @click="field.type='password'"/>
                            </b-input-group-text>
                            <b-form-input @keyup.enter="editEnterButton"
                                          v-bind:class="{ 'border-danger': curObject[field.name]==='' && field.required}"
                                          :placeholder="field.placeholder || field.label" :type="field.type"
                                          v-model="curObject[field.name]"></b-form-input>
                        </b-input-group>
                    </div>
                    <div v-else>
                        <label class="form-check-label">{{ field.label }}:</label>
                        <b-form-input @keyup.enter="editEnterButton"
                                      v-bind:class="{ 'border-danger': curObject[field.name]==='' && field.required}"
                                      :placeholder="field.placeholder || field.label" :type="field.type"
                                      v-model="curObject[field.name]"/>
                    </div>
                    <br>
                </div>
                <slot name="editModalInput"></slot>
            </div>
            <br>
            <div v-if="curObject.active !== undefined">
                <div class="row">
                    <div class="col-6">
                        <div v-if="curObject.active">
                            <b-button @click="disableModal=true; nameVerification=''" block variant="warning">Disable
                                {{ upper(objType.one) }}
                            </b-button>
                        </div>
                        <div v-else>
                            <b-button @click="disableEnable" block variant="success">Enable {{ upper(objType.one) }}
                            </b-button>
                        </div>
                    </div>
                    <div class="col-6">
                        <b-button @click="deleteModal=true" block variant="danger">Delete {{ upper(objType.one) }}
                        </b-button>
                    </div>
                </div>
            </div>
            <div v-else>
                <b-button @click="deleteModal=true" block variant="danger">Delete {{ upper(objType.one) }}</b-button>
            </div>
            <br>
            <div v-if="curObject.created_date">
                <p style="text-align:center; color: #7f8c8d; font-size: small">Date Created: {{ readableDate }}</p>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="editModal=false">Cancel</b-button>
                <slot name="editModalFooter"></slot>
                <b-button :disabled="editFormDisabledOk()" size="sm" variant="primary" @click="editSubmit">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Delete Modal-->
        <b-modal no-close-on-backdrop :title="'Confirm Delete'" class="modal-dark" v-model="deleteModal"
                 ok-variant="danger">
            <p>If you would like to delete this {{ objType.one }} please type the name below.</p>
            <br>
            <p>Enter "{{ curObject[nameField] }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input @keyup.enter="deleteEnterButton" v-model="nameVerification" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="deleteModal=false">Cancel</b-button>
                <b-button :disabled="nameVerification !== curObject[nameField]" size="sm" variant="danger"
                          @click="deleteSubmit">Delete
                </b-button>
            </template>
        </b-modal>

        <!--Are You Sure You Want To Delete multiple Pop-up-->
        <b-modal no-close-on-backdrop ok-title="Submit" title="Confirm Delete" class="modal-dark" v-model="multiDelete">
            <p>If you would like to permanently remove these devices please enter this phrase.</p>
            <p style="margin-bottom: 0">Devices:</p>
            <ul>
                <div v-for="(item, index) in newSelected" :key="index">
                    <li>
                        <div v-for="(form, index) in formFields" :key="index">
                            <p v-if="form.name !== '_id'" style="margin-bottom: 0">{{ form.label }}:
                                {{ item[form.name] }} </p>
                        </div>
                        <p> ID: {{ item._id }}</p>
                    </li>
                </div>
            </ul>
            <br>
            <p style="margin-bottom: 0">Enter "I would like to delete these items":</p>
            <b-form-group description="case sensitive">
                <b-form-input @keyup.enter="multiDeleteEnterButton()" v-model="nameVerification" id="basicName1"
                              type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="multiDelete=false">Cancel</b-button>
                <b-button :disabled="nameVerification !== 'I Would Like To Delete These Items'" size="sm"
                          variant="danger" @click="multiDeleteDevice(selected)">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Table-->
        <b-card style="width: 100%; height: 100%;">
            <div slot="header">
        <span class="d-flex w-100 justify-content-between">
          <h5 @click="editMode" style="margin-bottom: 0"><b>{{ tableTitle }}</b></h5>
          <fa-icon v-if="selected.length !== 0" :icon="['fas', 'trash']" style="padding-left: 10px" @click="openMultiDelete"/>
          <fa-icon v-else :icon="['fas', 'square-plus']" style="padding-left: 10px" @click="showCreateModal"/>
        </span>
            </div>
            <v-client-table ref="dTable" @row-click="rowClicked" style="width: 100%" :columns="columns2" :data="data"
                            :options="options" :theme="theme" class="dataTable">
                <a slot="edit" slot-scope="props" target="_blank" :href="props.row.info"
                   style="float: right; cursor:pointer" @click="showEditModal(props.row)">
                    <fa-icon :icon="['fas', 'pen-to-square']"/>
                </a>
                <div :slot="badgeField" slot-scope="props">
                    <b-badge :variant="getBadge(props.row)">{{ props.row[badgeField] }}</b-badge>
                </div>
                <div slot="image" slot-scope="props">
                    <img style="height:55px;" :src="getImage(props.row.image)" alt="">
                </div>
                <div slot="icon" slot-scope="props">
                    <span> <i style="cursor:auto"
                              :class="props.row.icon + ' icons font-lg'"></i>  &nbsp;&nbsp; {{ props.row.icon }}</span>
                </div>
                <div slot="constellationnames" slot-scope="props"
                     style="line-height: 1;margin-bottom: 0; display:inline">
                    <span v-for="(name,index) in props.row.constellationnames" :key="index"> {{ name }}<span
                        v-if="index!==props.row.constellationnames.length-1">,&nbsp;</span></span>
                </div>
                <div slot="phoneNumber" slot-scope="props">
                    <span>+{{ props.row.countryCode }} ({{ props.row.phoneNumber.substring(0, 3) }}) {{ props.row.phoneNumber.substring(3, 6) }}-{{ props.row.phoneNumber.substring(6, 10) }} </span>
                </div>
                <div slot="h__edit">
                    <span style="float: right">Edit</span>
                </div>
                <div slot="h__info">
                    <span style="float: right">info</span>
                </div>
                <b-form-checkbox slot="check" slot-scope="props" v-model="selected" :value="props.row._id"/>
                <div slot="h__check">
                    <b-form-checkbox type="checkbox" class="check-all"
                                     @change="selected = unmarkAndMarkAll(selected, allMarked)"></b-form-checkbox>
                </div>
                <div slot="location" slot-scope="props" v-if="props.row.location">
                    <span v-if="props.row.location.street">{{ props.row.location.street }}</span> <span
                    v-if="props.row.location.city">, {{ props.row.location.city }}</span> <span
                    v-if="props.row.location.state">{{ props.row.location.state }}</span> <span
                    v-if="props.row.location.postal">, {{ props.row.location.postal }}</span>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle"
                       triggers=""></b-tooltip>
        </b-card>

    </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import blackhole from "@/services/blackhole";

Vue.use(ClientTable)

export default {
    name: 'Users',
    data: () => {
        return {
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            multiDeleteCount: 0,
            newSelected: [],
            multiDelete: false,
            selected: [],
            allMarked: false,
            multiSelect: false,
            editModal: false,
            deleteModal: false,
            createModal: false,
            disableModal: false,
            curObject: {},
            newObject: {},
            editOkDisabled: true,
            newOkDisabled: true,
            nameVerification: "",
            readableDate: "",


            //Data Table
            columns2: [],
            options: {
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            theme: 'bootstrap4',

        }
    },
    props: {
        tableTitle: String,
        data: Array,
        nameField: String,
        formFields: Array,
        objType: Object,
        badgeField: String,
        editNotSubmittable: false,
        createNotSubmittable: false,
        tabs: Array,

        //Data Table Props
        orderBy: Object,
        columns: Array,
        headings: Object,
        sortable: Array,
        filterable: Array,
    },
    mounted() {
        this.local = localStorage
    },
    created() {
        this.combineDataTableOptions()
    },
    methods: {
        getImage(id) {
            return blackhole.readImage(id);
        },
        openMultiDelete() {
            this.nameVerification = '';
            let newSelected = []
            this.selected.forEach(function (select) {
                this.data.forEach(function (data) {
                    if (data._id === select) {
                        newSelected.push(data)
                    }
                }.bind(this))
            }.bind(this))
            this.newSelected = newSelected;
            this.multiDelete = true;
        },
        combineDataTableOptions() {
            this.columns2 = this.columns;
            this.options.headings = this.headings;
            this.options.sortable = this.sortable;
            this.options.filterable = this.filterable;
            this.options.orderBy = this.orderBy;
        },
        createEnterButton() {
            if (!this.createFormDisabledOk()) {
                this.createModal = false;
                this.createSubmit();
            }
        },
        editEnterButton() {
            if (!this.editFormDisabledOk()) {
                this.editModal = false;
                this.editSubmit();
            }
        },
        deleteEnterButton() {
            if (this.nameVerification === this.curObject[this.nameField]) {
                this.deleteModal = false;
                this.deleteSubmit();
            }
        },
        disableEnterButton() {
            if (this.nameVerification === this.curObject[this.nameField]) {
                this.disableModal = false;
                this.disableEnable();
            }
        },
        makeDate(time) {
            let created_date = time;
            let date = [];
            let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            date.push(created_date.substring(0, 4));
            date.push(created_date.substring(5, 7));
            date.push(created_date.substring(8, 10));
            date.push(created_date.substring(11, 16));
            let mlong = months[parseInt(date[1])];

            function tConvert(time) {
                time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) {
                    time = time.slice(1);
                    time[5] = +time[0] < 12 ? 'AM' : 'PM';
                    time[0] = +time[0] % 12 || 12;
                }
                return time.join('');
            }

            date[3] = tConvert(date[3])
            this.readableDate = mlong + " " + date[2] + ", " + date[0] + " at " + date[3] + " UTC"
        },
        disableEnable() {
            this.disableModal = false;
            if (this.curObject.active === false) {
                this.curObject.active = true;
            } else if (this.curObject.active === true) {
                this.curObject.active = false;
            }
            this.$emit('disable-enable', this.curObject);
            this.editModal = false;
        },
        showEditModal(row) {
            this.nameVerification = null;
            this.editModal = true;
            this.curObject = JSON.parse(JSON.stringify(row));
            this.$emit('edit-show', this.curObject);
            if (this.curObject.created_date) {
                this.makeDate(this.curObject.created_date)
            }
        },
        showCreateModal() {
            this.nameVerification = null;
            this.createModal = true;
            this.newObject = Object.assign({}, {});
            this.formFields.forEach((field, index) => {
                if (field.default) {
                    this.newObject[field.name] = field.default
                }
            })
            this.$emit('create-show', this.curObject);

        },
        createSubmit() {
            this.createModal = false;
            this.$emit('create-submit', this.newObject);
        },
        editSubmit() {
            this.editModal = false;
            this.$emit('edit-submit', this.curObject);
        },
        multiDeleteEnterButton() {
            if (this.nameVerification === 'I would like to delete these items') {
                this.multiDeleteDevice(this.selected);
                this.multiDelete = false;
            }
        },
        multiDeleteDevice(selected) {
            this.multiDeleteCount = 0;
            selected.forEach(function (i) {
                this.multiDeleteCount++;
                this.$emit('delete-submit', i, this.multiDeleteCount === selected.length);
            }.bind(this))
        },
        deleteSubmit() {
            this.deleteModal = false;
            this.editModal = false;
            this.$emit('delete-submit', this.curObject._id)
        },
        hideModal() {
            this.$emit('hide-modal');
            //reset the password field
            this.formFields.forEach(function (field) {
                if (field.name === "password") {
                    field.type = "password"
                }
            });
        },
        editFormDisabledOk() {
            for (let field of this.formFields) {
                if ((field.required === true && this.curObject[field.name] === "") || this.editNotSubmittable) {
                    return true;
                }
            }
            return false;
        },
        createFormDisabledOk() {
            for (let field of this.formFields) {
                if ((field.required === true && (this.newObject[field.name] === "" || this.newObject[field.name] === undefined)) || this.createNotSubmittable) {
                    return true;
                }
            }
            return false;
        },
        getRowCount(items) {
            return items.length
        },
        upper(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disapear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            } else if (!this.editModal) {
                this.$emit('row-clicked', item.row)

            }
        },
        getBadge(status) {
            return status.status === 'inactive' ? 'danger'
                : (status.status) === 'active' ? 'success' : 'success'
        },
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (this.$refs.dTable.allFilteredData) {
                    this.$refs.dTable.allFilteredData.forEach(function (item) {
                        temp.push(item._id);
                    });
                }
                this.allMarked = true;
                return (temp)
            } else {
                this.allMarked = false;
                return ([])
            }
        },
        editMode() {
            if (!this.multiSelect) {
                this.columns2.unshift('check');
                this.multiSelect = true;
            } else {
                this.columns2.splice(0, 1);
                this.multiSelect = false;
                this.selected = [];
            }
        },
    }
}
</script>

